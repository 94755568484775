<template>
    <el-row>
        <div class="item">
              <!-- 是否退货 0否 1是 -->
             <div class="itemLabel" v-if="goodsInfo.isRefund==1">
              </div>
             <p  class="itemLabelP" v-if="goodsInfo.isRefund==1">
               <span>已退货</span>    
               <span>退货数量:{{goodsInfo.refundCount}}{{goodsInfo.unit}}</span>   
               <span>退款金额:{{goodsInfo.refundAmount}}</span> 
            </p>
             <el-col :span="9"><ShopImg :msg="goodsInfo"></ShopImg></el-col>
             <el-col :span="4" class="text-center">{{goodsInfo.originalPrice}}</el-col>
             <el-col :span="3" class="text-center">{{goodsInfo.goodsCount}}</el-col>
             <!-- {{goodsInfo.payablePrice}} -->
             <el-col :span="3" class="text-center ">{{total}}</el-col>
             <el-col :span="3" class="text-center total">{{goodsInfo.payablePrice}}</el-col>
             <el-col    class="text-center"  :span="3">
                  <p v-if="goodsInfo.isRefund!=1" class="msgBox" @click="downFun(goodsInfo)">下载药检报告</p>
                  <p class="msgBoxs"   @click="downProdetailFun(goodsInfo)">商品首营下载</p>
              </el-col>
             <!-- <el-col   v-if="goodsInfo.isRefund==1" :span="3"  class="text-center msgBox">无</el-col> -->
            
        </div>
    </el-row>
</template>
<script>
import {GetOrderDetailReport} from "api/order.js";
import { GetProductDetail } from "api/product.js";
const ShopImg = ()=>import("components/common/ShopImg.vue");
import jsPDF from "jspdf";
export default {
  name:"OrderShopInfo",
  data(){
      return{
          total:0,
          loading: false,
          drugCertificatePic:''
      }
  },
  props:{
    goodsInfo:{
      type:Object
    }
  },
 created(){
   this.total=(Number(this.goodsInfo.originalPrice)*Number(this.goodsInfo.goodsCount)).toFixed(2)
 },
  methods: {
    // 下载商品药检报告
   downFun(Goods){
      GetOrderDetailReport(Goods.id,Goods.goodNum).then(res=>{
            if (res.data.type == "application/json") {
              this.$message.error("暂无药检报告");
            } else {
               // 下载pdf
                this.pdfUrl = window.URL.createObjectURL(new Blob([res.data], { type: `application/pdf` }));
                const fname = Goods.goodsName+'药检报告'; // 下载文件的名字
                const link = document.createElement('a');
                link.href = this.pdfUrl;
                link.setAttribute('download', fname);
                document.body.appendChild(link);
                link.click();
            }
      })
   },


   //下载商品首营下载
  //  downProdetailFun(Goods){
  //     console.log('Goods',Goods)
  //  },
  async getRotatedImageData(image) {
      try {
        // 创建一个 Promise，确保图片加载完成后再执行后续操作
        const loadImagePromise = new Promise((resolve) => {
          const img = new Image();
          img.crossOrigin = "anonymous"; // 设置图片为匿名 CORS 请求
          img.onload = () => resolve(img);
          img.onerror = () => {
            // alert("src");
            console.log("srcError")
          };
          img.src = image;
        });

        // 等待图片加载完成
        const loadedImage = await loadImagePromise;

        // 如果图片宽大于高，旋转成纵向
        const shouldRotate = loadedImage.width > loadedImage.height;

        // 计算图片的旋转角度
        const rotationAngle = shouldRotate ? 90 : 0;

        // 创建一个 Canvas 元素用于旋转图片
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");

        // 如果需要旋转，设置 Canvas 尺寸
        if (shouldRotate) {
          canvas.width = loadedImage.height;
          canvas.height = loadedImage.width;
        } else {
          canvas.width = loadedImage.width;
          canvas.height = loadedImage.height;
        }

        // 在 Canvas 上绘制旋转后的图片
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.translate(canvas.width / 2, canvas.height / 2);
        context.rotate(rotationAngle * (Math.PI / 180));
        context.drawImage(
          loadedImage,
          -loadedImage.width / 2,
          -loadedImage.height / 2
        );

        // 将旋转后的图片转换为 Base64 数据 URI，保持原始宽高比例
        const rotatedImageDataUri = canvas.toDataURL("image/jpeg", 1.0);

        return { rotatedImageDataUri, rotationAngle };
      } catch (error) {
        console.error("Error rotating image:", error);
        return null;
      }
    },
   async downProdetailFun(Goods) {
      try {
        const parmes = {
          id: Goods.goodsId,
          isRecentExpiration: Goods.isRecentExpiration,
        };
        await GetProductDetail(parmes).then((res) => {
            console.log(res.data.data)
            this.drugCertificatePic=res.data.data.drugCertificatePic
        })

              this.loading = true;
              const pdf = new jsPDF();
              const drugCertificatePic = this.drugCertificatePic || "";

              console.log('Goods',Goods)
              const imagePaths = drugCertificatePic.split(",");

              // 定义每页的高度和宽度（根据需要调整）
              const pageWidth = pdf.internal.pageSize.width;
              const pageHeight = pdf.internal.pageSize.height;

              if (drugCertificatePic === "") {
                this.loading = false;
                return;
              }
              // 循环遍历图片列表
              for (let index = 0; index < imagePaths.length; index++) {
                const imagePath = imagePaths[index];

                // 获取图片的旋转后的数据 URI 和旋转角度
                const { rotatedImageDataUri } = await this.getRotatedImageData(
                  imagePath.replace("https", "http")
                );

                // 添加旋转后的图片到 PDF，保持原始宽高比例
                pdf.addImage(
                  rotatedImageDataUri,
                  "JPEG",
                  0,
                  0,
                  pageWidth,
                  pageHeight,
                  null,
                  "FAST"
                );
                // 如果不是最后一页，添加新的一页
                if (index !== imagePaths.length - 1) {
                  pdf.addPage();
                }
              }

              // 保存或下载 PDF 文件
              pdf.save("output.pdf");
              this.loading = false;

              
      } catch (error) {
        console.error("Error generating PDF:", error);
        this.loading = false;
      }
    },





  },
  components:{
    ShopImg  
  },
  
};
</script>



<style lang="less" scoped>
  @import '~style/index.less';
 .item {
    display: flex;
    align-items: center;
    padding: 15px 0px;
    border-left:1px solid #F0F0F0;
    border-bottom: 1px solid #F0F0F0;
    border-right:1px solid #F0F0F0;
    color: #666;
    position: relative;
    margin-bottom:5px ;
    .itemLabel{
      position:absolute;
      left: 0;
      top: 0;
      z-index: 9;
      background: #ccc;
      opacity:0.5;
      width: 100%;
      height: 100%;
    }
    .itemLabelP{
        position:absolute;
        left: 0;
        top: 0;
        z-index:10;
        opacity:1;
        width: 100%;
        height: 100%;
        opacity: 1;
        font-size:26px;
        font-weight: bold;
        line-height:126px;
        text-align: center;
        color: #ff3333;
        span{
          padding-right:40px
        }
      }
    .total {
        font-weight: bold;
    }
    .msgBox{
      font-size: 14px;
      cursor: pointer;
      &:hover{
        color: #ff3333;
      }
    }
    .msgBoxs{
      font-size: 14px;
      cursor: pointer;
      &:hover{
        color: #ff3333;
      }
    }
 }
 

 
</style>